import React from "react";
import styled from "styled-components";

// sub-components
import Contain from "./contain";

const Faq = styled.section`
  padding: 0 0 5rem;
`;

const FaqRow = styled.div`
  @media (min-width: 64rem) {
    display: flex;
  }
`;

const Column40 = styled.div`
  @media (min-width: 64rem) {
    width: 40%;
  }
`;
const Column60 = styled.div`
  @media (min-width: 64rem) {
    width: 60%;
    padding-left: 2rem;
  }
`;

const Heading = styled.h2`
  margin: 0 0 1rem;
`;

const Excerpt = styled.p`
  margin: 0 0 2rem;
  font-weight: 600;

  @media (min-width: 64rem) {
    font-size: 1.5rem;
    line-height: 1.6;
  }
`;

const Button = styled.button`
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: underline;
  border: 0;
  background: none;
  cursor: pointer;
  outline: 0;

  &:hover {
    color: #3182ce;
  }
`;

export const Details = styled.details`
  background: #fff;
  border-radius: 0.4rem;
  border: 1px solid #181818;
  margin-bottom: 1.5rem;
  transition: 150ms;
  transition-timing-function: ease-in-out;
  position: relative;

  &:last-child {
    margin: 0;
  }

  &:hover {
    background: #eee;
  }

  &[open] {
    border: 1px solid transparent;
    summary::-webkit-details-marker {
      color: #5e5e5e;
    }
  }

  p {
    padding: 0 1.5rem 1.5rem 2.5rem;
    line-height: 1.6;
  }
`;

export const Summary = styled.summary`
  color: #181818;
  padding: 1.5rem 1.5rem 1.5rem 2.5rem;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  cursor: pointer;

  &::-webkit-details-marker {
    color: #181818;
    position: absolute;
    top: 1.9rem;
    left: 1rem;
  }
`;

export default () => {
  return (
    <Faq>
      <Contain>
        <FaqRow>
          <Column40>
            <Heading>Have questions about Quickpage?</Heading>
            <Excerpt>
              Need more help? <br />
              <Button onClick={() => window.Intercom("show")}>
                Chat with us now.
              </Button>
            </Excerpt>
          </Column40>

          <Column60>
            <Details open>
              <Summary>
                Does my customer need to have the Quickpage app to view my page
                or watch my video?
              </Summary>

              <p>
                NOPE - Your customer can view your Quickpage on any smartphone,
                tablet or computer without having to download a thing.
              </p>
            </Details>

            <Details>
              <Summary>Will Quickpage work with my current CRM?</Summary>

              <p>
                YES. Keep your CRM. We've designed Quickpage in a way that
                allows you to use our app alongside your CRM. Quickpages are
                easily embedded into your CRM using a copy paste code and are
                still trackable inside your Quickpage account.
              </p>
            </Details>

            <Details>
              <Summary>
                Can I attach more than just 1 video to a Quickpage?
              </Summary>

              <p>
                Yes! With Quickpage, you can attach unlimited videos, images,
                pdfs/documents, YouTube videos, testimonials, even website links
                that allow, and deliver it to your customers all on ONE page.
              </p>
            </Details>

            <Details>
              <Summary>
                How is sending a Quickpage different from sending an email with
                a YouTube link?
              </Summary>

              <p>
                THE DIFFERENCE IS NIGHT AND DAY! There's no bigger distraction
                than youtube, and no better place to send your customers if you
                want to risk them seeing your competitor! <br /> <br />
                With Quickpage, your customer has a distraction free page made
                just for them. Plus, we’ve attached the entire YouTube database
                making it easy for you to add any YouTube videos to your
                Quickpages.
              </p>
            </Details>

            <Details>
              <Summary>How long does it take to build a Quickpage?</Summary>

              <p>
                You can build and share a page in as little as 1 minute. The
                average time spent making a Quickpage is about 5 minutes. With
                our duplicate page and template page features, we’ve made the
                video follow up process even more scalable.
              </p>
            </Details>

            <Details>
              <Summary>
                How will I know when my customer views the page and watches my
                video?
              </Summary>

              <p>
                The moment your customer opens the page you will get both a push
                notification on your device and an email. You will be able to
                see when they are live on the page, so that you can make
                yourself available to answer any of their questions in live
                chat.
              </p>
            </Details>

            <Details>
              <Summary>
                Can I send a single Quickpage to more than 1 person?
              </Summary>

              <p>
                The sweet spot for Quickpage is the one to one personalized
                video but sometimes you have a message you’d like to send out to
                many or all of your clients. <br />
                SO YES. You can send and share the same page to 1 person or
                1000+. Each individual person you send that Quickpage to will be
                blind copied and each able to communicate with you privately on
                that page.
              </p>
            </Details>

            <Details>
              <Summary>
                Do I need to get SMS text approval from my customer to send a
                Quickpage by text?
              </Summary>

              <p>
                We do have SMS text approval baked into the app so that you can
                be compliant with your company’s policies. When you text through
                your Quickpage contacts, you will need to get SMS text approval,
                which is simple and fast to do right inside the app. <br />
                We also made it easy to send a Quickpage without getting SMS
                text approval- and allow you to text a Quickpage right from your
                own phone.
              </p>
            </Details>

            <Details>
              <Summary>
                Am I able to easily upload my contacts into my Quickpage
                account?
              </Summary>

              <p>
                We are currently developing the self import for contacts. In the
                meantime, we offer our white glove service of importing your
                contacts for you.
              </p>
            </Details>

            <Details>
              <Summary>Can I record my screen with Quickpage?</Summary>

              <p>
                Yes! When using our desktop version, you can record your screen
                and add that recording to a Quickpage.
              </p>
            </Details>

            <Details>
              <Summary>Does Quickpage have closed captions on videos?</Summary>

              <p>
                Yes! Quickpage uses Alexa to auto generate captions on your
                videos so that your recipients can watch your videos with their
                volume off. There is a bit of a learning curve so you’ll have to
                give Alexa some time to get to know you. :)
              </p>
            </Details>

            <Details>
              <Summary>Does Quickpage work for teams?</Summary>

              <p>
                Absolutely! Teams have the benefits of sharing media folders
                (videos, images, docs), canned messages (email scripts) and even
                Quickpage templates.
              </p>
            </Details>

            <Details>
              <Summary>Can I cancel my account anytime?</Summary>

              <p>
                Yes. We don’t require any contracts and you can cancel your
                account anytime. We are pleased to offer a 30 day money back
                guarantee if you aren’t satisfied with the service or you just
                never had time to fully utilize it. You will need to be logged
                in on your desktop account in order to cancel your subscription.
                If you ever need help, our live support is always available to
                assist you.
              </p>
            </Details>
          </Column60>
        </FaqRow>
      </Contain>
    </Faq>
  );
};
