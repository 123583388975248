import React from "react";
import styled from "styled-components";

const Hero = styled.section`
  background: #dbf4ea;
  position: relative;
  padding: 8rem 0 5rem;

  @media (min-width: 64rem) {
    padding: 10rem 0 5rem;
  }

  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    height: 600px;
    bottom: -300px;
    width: 100%;
    transform: skewY(-8deg);
    background: #dbf4ea;
  }
`;

export default ({ children }) => {
  return <Hero>{children}</Hero>;
};
