import React from "react";
import styled from "styled-components";

// Layout
import Layout from "../components/layout";
import Contain from "../components/contain";
import Cta from "../components/cta";
import Seo from "../components/seo";

// Components
import HeroPage from "../components/hero-page";
import Heading from "../components/heading";
import Faq from "../components/faq";

export default class FAQ extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="The Video Follow Up App for Sales Professionals | Quickpage"
          description="Follow up better. Close deals faster. With video."
          keywords="video, email, video email"
          url="https://sutton.quickpage.io/how-it-works"
        />
        <HeroPage />

        <Faq />

        <Cta location={this.props.location} />
      </Layout>
    );
  }
}
